import type { Dispatch, ReactNode, SetStateAction } from "react";
import { createContext, useContext, useEffect, useState } from "react";

export type ShoppingCartVisible =
  | "hidden"
  | "shoppingCart"
  | "addedToShoppingCart";
const ModalContext = createContext<{
  mainMenuVisible: boolean;
  setMainMenuVisible: Dispatch<SetStateAction<boolean>>;
  shoppingCartVisible: ShoppingCartVisible;
  setShoppingCartVisible: Dispatch<SetStateAction<ShoppingCartVisible>>;
  filterVisible: boolean;
  setFilterVisible: Dispatch<SetStateAction<boolean>>;
  searchVisible: boolean;
  setSearchVisible: Dispatch<SetStateAction<boolean>>;
}>({
  mainMenuVisible: false,
  setMainMenuVisible: () => {
    throw Error("Not implemented");
  },
  shoppingCartVisible: "hidden",
  setShoppingCartVisible: () => {
    throw Error("Not implemented");
  },
  filterVisible: false,
  setFilterVisible: () => {
    throw Error("Not implemented");
  },
  searchVisible: false,
  setSearchVisible: () => {
    throw Error("Not implemented");
  },
});
ModalContext.displayName = "Modal Context";

interface Props {
  children: ReactNode;
}

export const ModalProvider = (props: Props) => {
  const [mainMenuVisible, setMainMenuVisible] = useState<boolean>(true);
  const [shoppingCartVisible, setShoppingCartVisible] =
    useState<ShoppingCartVisible>("addedToShoppingCart");

  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [searchVisible, setSearchVisible] = useState<boolean>(false);

  useEffect(() => {
    if (shoppingCartVisible !== "hidden") {
      setMainMenuVisible(false);
      setFilterVisible(false);
      setSearchVisible(false);
    }
  }, [shoppingCartVisible]);

  useEffect(() => {
    if (mainMenuVisible) {
      setShoppingCartVisible("hidden");
      setFilterVisible(false);
      setSearchVisible(false);
    }
  }, [mainMenuVisible]);

  useEffect(() => {
    if (filterVisible) {
      setShoppingCartVisible("hidden");
      setMainMenuVisible(false);
      setSearchVisible(false);
    }
  }, [filterVisible]);

  useEffect(() => {
    if (searchVisible) {
      setShoppingCartVisible("hidden");
      setMainMenuVisible(false);
      setFilterVisible(false);
    }
  }, [searchVisible]);

  return (
    <ModalContext.Provider
      value={{
        mainMenuVisible,
        setMainMenuVisible,
        shoppingCartVisible,
        setShoppingCartVisible,
        filterVisible,
        setFilterVisible,
        searchVisible,
        setSearchVisible,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
